const LocationForm = (function () {
  // Module Functions
  const initialize = function () {
    $('#location_abbr').on('change', function () {
      var abbr = $(this).val();
      updateCampaignField(abbr);
    });
  };

  // Private Methods
  function updateCampaignField (abbr) {
    const $campaignField = $('#location_utm_links_attributes_0_campaign');

    if (abbr) {
      $campaignField.val(`LOC_${abbr}`).trigger('change');
    }
  }

  // Module Public API
  return {
    init: initialize
  };
})();

$(document).on('turbolinks:load', function () {
  LocationForm.init();
});
