// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import 'bootstrap';
import '../stylesheets/application';
import 'datetime';
import 'utm_links';
import 'course_form';
import 'user_form';
import 'location_form';
import 'markdown_editor';
import 'select2';
import 'datatables'; // See package.json
import 'courses';
import 'course_types';
import 'registrants';
import 'advisors';
import 'locations';
import 'custom_redirects';
import 'contact_us_messages';
import 'users';
import 'training_videos';

require('@rails/ujs').start();
require('turbolinks').start();
require('@rails/activestorage').start();
require('channels');
require('jquery');
require('@nathanvda/cocoon');

$(document).on('turbolinks:load', function () {
  // enable popper and tooltips
  $('[data-behavior="tooltip"]').tooltip();
  $('[data-behavior="popover"]').popover({
    trigger: 'hover',
    html: true
  });
});

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
