import { dataTablesHandler } from 'datatables_handler';

const locations = (function () {
  const init = function () {
    initLocationDataTable();
  };

  const initLocationDataTable = function () {
    // If we aren't on the Location index page, don't continue.
    if (!$('table#locations-datatable').length) return;

    // Turbolinks tries to fire the course init when clicking away from the page. To prevent
    // datatables from throwing an error we have to check and only initialize datatables if it
    // hasn't been initialized already.
    if ($('#locations-datatable_wrapper').length > 0) return;

    dataTablesHandler.init('locations-datatable', {
      pageLength: 20,
      ajax: { url: $('#locations-datatable').data('source') },
      createdRow: appendActionsColumn,
      columns: [
        { data: 'id' },
        { data: 'title' },
        { data: 'courses_count' },
        { data: 'abbr' },
        { data: 'building' },
        { data: 'street_address' },
        { data: 'city' },
        { data: 'state' },
        { data: 'zip' },
        { data: 'image' }
      ]
    });
  };

  const appendActionsColumn = function (row, data, dataIndex, cells) {
    const actions = `
      <td class="actions">
        <a href="/admin/locations/${parseInt(data.id)}/">Show</a>
        <a href="/admin/locations/${parseInt(data.id)}/edit">Edit</a>
        <a href="/admin/locations/${parseInt(data.id)}/" data-method="delete" data-confirm="Are you sure?">Destroy</a>
      </td>`;
    $(row).append(actions);
  };

  return {
    init: init
  };
}());

$(document).on('turbolinks:load', function () {
  locations.init();
});
