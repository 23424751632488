const dataTablesHandler = (function () {
  const init = function (tableId, options) {
    // Set up our default options that we'll want consistent across all datatables.
    options.initComplete = function () {
      this.api().columns().every(initSearchField);
    };
    options.order = [[0, 'desc']];
    options.dom = 'iptrip';
    options.processing = true;
    options.serverSide = true;
    options.lengthChange = false;
    options.pagingType = 'first_last_numbers';

    addSearchInputsToFooter(tableId);

    // Init our DataTable
    $('table#' + tableId).dataTable(options).api();

    // Whenever we make an AJAX call to the back end, hook in and get the filtered IDs for our
    // export button functionality.
    // https://datatables.net/reference/event/xhr
    $('table#' + tableId).on('xhr.dt', function (_e, _settings, json, _xhr) {
      dataTablesHandler.updateExportLink(json.filtered_ids);
    });

    adjustStyles(tableId);
  };

  const addSearchInputsToFooter = function (tableId) {
    $('table#' + tableId + ' tfoot th').each(function () {
      if ($(this).text() === '') return; // Skip columns without a header (action buttons)
      // data-lpignore removes LastPass icon from form fields
      // https://stackoverflow.com/questions/20954944
      $(this).html(`<input type="text" class="form-control ${$(this).text()}" placeholder="All" data-lpignore="true" />`);
    });
  };

  const updateExportLink = function (ids) {
    const $export = $('#export-rows');

    if (ids === undefined || ids.length === 0) {
      $export.addClass('disabled');
      $export.prop('href', '');
      return;
    }

    // Generate our link with the IDs in the param format preferred by Rails.
    const url = ids.reduce(function (output, id) {
      return output + 'ids%5B%5D=' + id + '&';
    }, '/admin/registrants.csv?');

    $export.prop('href', url.slice(0, -1));
    $export.removeClass('disabled');
  };

  const initSearchField = function () {
    const dataTable = this;
    let searchValue;
    let debounce;

    $('input', this.footer()).on('keyup change clear', function () {
      if (dataTable.search() === this.value) return;
      clearTimeout(debounce);
      searchValue = this.value;

      // We are delaying our search till there's been a pause for 500 ms.
      // Otherwise we'd be firing a query each time the user types a letter.
      debounce = setTimeout(function () {
        dataTable.search(searchValue).draw();
      }, 500);
    });
  };

  const adjustStyles = function (tableId) {
    // Add th for "actions" column and display footer search fields in header
    $('#' + tableId + ' tfoot tr').append('<th></th>').appendTo('#' + tableId + ' thead');
  };

  return {
    init: init,
    updateExportLink: updateExportLink
  };
})();

export { dataTablesHandler };
