const UserForm = (function () {
  // Module Functions
  const initialize = function () {
    $('#user_advisor_id').select2();
  };

  // Module Public API
  return {
    init: initialize
  };
})();

$(document).on('turbolinks:load', function () {
  UserForm.init();
});
