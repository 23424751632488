import { dataTablesHandler } from 'datatables_handler';

const customRedirects = (function () {
  const init = function () {
    initCustomRedirectsDataTable();
  };

  const initCustomRedirectsDataTable = function () {
    // If we aren't on the Custom Redirects index page, don't continue.
    if (!$('table#custom-redirects-datatable').length) return;

    // Turbolinks tries to fire the customRedirects init when clicking away from the page. To
    // prevent datatables from throwing an error we have to check and only initialize datatables
    // if it hasn't been initialized already.
    if ($('#custom-redirects-datatable_wrapper').length > 0) return;

    dataTablesHandler.init('custom-redirects-datatable', {
      pageLength: 20,
      ajax: { url: $('#custom-redirects-datatable').data('source') },
      createdRow: appendActionsColumn,
      columns: [
        { data: 'id' },
        { data: 'origin' },
        { data: 'destination' },
        { data: 'trips' }
      ]
    });
  };

  const appendActionsColumn = function (row, data, dataIndex, cells) {
    const actions = `
      <td class="actions">
        <a href="/admin/custom_redirects/${parseInt(data.id)}/">Show</a>
        <a href="/admin/custom_redirects/${parseInt(data.id)}/edit">Edit</a>
        <a href="/admin/custom_redirects/${parseInt(data.id)}" data-method="delete" data-confirm="Are you sure?" rel="nofollow">Destroy</a>
      </td>`;
    $(row).append(actions);
  };

  return {
    init: init
  };
}());

$(document).on('turbolinks:load', function () {
  customRedirects.init();
});
