import { dataTablesHandler } from 'datatables_handler';

const registrants = (function () {
  const init = function () {
    initRegistrantDataTable();
  };

  const initRegistrantDataTable = function () {
    // If we aren't on the Registrant index page, don't continue.
    if (!$('table#registrants-datatable').length) return;

    // Turbolinks tries to fire the course init when clicking away from the page. To prevent
    // datatables from throwing an error we have to check and only initialize datatables if it
    // hasn't been initialized already.
    if ($('#registrants-datatable_wrapper').length > 0) return;

    dataTablesHandler.init('registrants-datatable', {
      pageLength: 20,
      ajax: { url: $('#registrants-datatable').data('source') },
      createdRow: appendActionsColumn,
      columns: [
        { data: 'id' },
        { data: 'first_name' },
        { data: 'last_name' },
        { data: 'email' },
        { data: 'utm_source' },
        { data: 'utm_campaign' },
        { data: 'created_at' }
      ]
    });
  };

  const appendActionsColumn = function (row, data, dataIndex, cells) {
    const actions = `
      <td class="actions">
        <a href="/admin/registrants/${parseInt(data.id)}/">Details</a>
      </td>`;
    $(row).append(actions);
  };

  return {
    init: init
  };
}());

$(document).on('turbolinks:load', function () {
  registrants.init();
});
