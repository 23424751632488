const CourseForm = (function () {
  // Module Functions
  const initialize = function () {
    $('#course_course_type_id').on('change', function () {
      updateCampaignField();
      updateTitle();
    });
    $('#course_location_id').on('change', function () {
      updateCampaignField();
      updateTitle();
    });
    $('#course_advisor_id').select2();
  };

  // Private Methods
  function updateCampaignField () {
    const $campaignField = $('#course_utm_links_attributes_0_campaign');
    const courseAbbr = $('#course_course_type_id option:selected').attr('data-abbr');
    const locationAbbr = $('#course_location_id option:selected').attr('data-abbr');

    if (courseAbbr && locationAbbr) {
      $campaignField.val(`${courseAbbr}_${locationAbbr}`).trigger('change');
    }
  }

  function updateTitle () {
    // This grabs the selected option IF it is not the default "Select a value..." option.
    const courseTypeTitle = $('#course_course_type_id').find(':selected:not([value=""])').text();
    const locationTitle = $('#course_location_id').find(':selected:not([value=""])').text();
    const $courseTitle = $('#course_title');

    if (courseTypeTitle && locationTitle) {
      $courseTitle.val(courseTypeTitle + ' @ ' + locationTitle);
    }
  }

  // Module Public API
  return {
    init: initialize
  };
})();

$(document).on('turbolinks:load', function () {
  CourseForm.init();
});
