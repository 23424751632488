import { dataTablesHandler } from 'datatables_handler';

const contactUsMessages = (function () {
  const init = function () {
    initContactUsMessagesDataTable();
  };

  const initContactUsMessagesDataTable = function () {
    // If we aren't on the Contact Us Messages index page, don't continue.
    if (!$('table#contact-us-messages-datatable').length) return;

    // Turbolinks tries to fire the contactUsMessages init when clicking away from the page. To
    // prevent datatables from throwing an error we have to check and only initialize datatables
    // if it hasn't been initialized already.
    if ($('#contact-us-messages-datatable_wrapper').length > 0) return;

    dataTablesHandler.init('contact-us-messages-datatable', {
      pageLength: 20,
      ajax: { url: $('#contact-us-messages-datatable').data('source') },
      createdRow: appendActionsColumn,
      columns: [
        { data: 'id' },
        { data: 'name' },
        { data: 'email' },
        { data: 'phone' },
        { data: 'message' },
        { data: 'spam_meta' },
        { data: 'created_at' }
      ]
    });
  };

  const appendActionsColumn = function (row, data, dataIndex, cells) {
    const actions = `
      <td class="actions">
        <a href="/admin/contact_us_messages/${parseInt(data.id)}/">Details</a>
        <a href="/admin/contact_us_messages/${parseInt(data.id)}/" data-method="delete" data-confirm="Are you sure?">Delete</a>
      </td>`;
    $(row).append(actions);
  };

  return {
    init: init
  };
}());

$(document).on('turbolinks:load', function () {
  contactUsMessages.init();
});
