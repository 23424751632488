import { dataTablesHandler } from 'datatables_handler';

const UtmLink = (function () {
  // Module Functions
  const initialize = function () {
    initUtmLinkEdit();
    initUtmLinksDatatable();
  };

  const initUtmLinkEdit = function () {
    const $utmField = $('.utm-field');
    const $copyBtn = $('#copy2clip');

    if ($copyBtn.length > 0) {
      $copyBtn.on('click', copy2Clipboard);
    }

    $utmField.on('keyup change', updateUtmUrl);
    updateUtmUrl();
  };

  // Private Methods
  const copy2Clipboard = function (e) {
    e.preventDefault();
    const $copyField = $('.copyable');

    const copyText = $copyField[0];
    copyText.select();
    document.execCommand('copy');
  };

  const updateUtmUrl = function () {
    const $linkCanvas = $('#utm-url').val(baseUrl);
    const baseUrl = $linkCanvas.data('base-url');
    const $fields = $('.utm-field');
    const params = {};

    // We don't know what the base URL is going to be during initial course creation. That value
    // will only be set if we're viewing/editing an existing course or UTM link.
    if (baseUrl === ''){
      $linkCanvas.val('PENDING');
      return;
    }

    $fields.each(function () {
      const $e = $(this);
      const val = $e.val();
      const tokens = $e.attr('id').split('_');
      const fieldName = tokens[tokens.length - 1];

      if (val && val !== '') {
        params['utm_' + fieldName] = val;
      }
    });

    $linkCanvas.val(utmUrl(params, baseUrl));
  };

  const utmUrl = function (params, url) {
    return url + '?' + $.param(params);
  };

  // Set up Datatables on the UTM Links index view
  const initUtmLinksDatatable = function () {
    // If we aren't on the UTM Link index page, don't continue.
    if (!$('table#utm-links-datatable').length) return;

    // Turbolinks tries to fire the UtmLink init when clicking away from the page. To prevent
    // datatables from throwing an error we have to check and only initialize datatables if it
    // hasn't been initialized already.
    if ($('#utm-links-datatable_wrapper').length > 0) return;

    dataTablesHandler.init('utm-links-datatable', {
      pageLength: 20,
      ajax: { url: $('#utm-links-datatable').data('source') },
      createdRow: appendActionsColumn,
      columns: [
        { data: 'id' },
        {
          data: 'base_url',
          render: function (data, type) {
            if (type === 'display') {
              const output = data.replace(' ', '<span class="utm-full-url">');
              return output.replace('|', '</span>');
            }
            return data;
          }
        },
        { data: 'source' },
        { data: 'medium' },
        { data: 'campaign' }
      ]
    });
  };

  const appendActionsColumn = function (row, data, dataIndex, cells) {
    const actions = `
      <td class="actions">
        <a href="/admin/utm_links/${parseInt(data.id)}/">Show</a>
        <a href="/admin/utm_links/${parseInt(data.id)}/edit">Edit</a>
        <a href="/admin/utm_links/${parseInt(data.id)}" data-method="delete" data-confirm="Are you sure?" rel="nofollow">Destroy</a>
      </td>`;
    $(row).append(actions);
  };

  // Module Public API
  return {
    init: initialize
  };
})();

$(document).on('turbolinks:load', function () {
  UtmLink.init();
});
