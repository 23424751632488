import { dataTablesHandler } from 'datatables_handler';

const courseTypes = (function () {
  const init = function () {
    initCourseTypeDataTable();
  };

  const initCourseTypeDataTable = function () {
    // If we aren't on the Course Type index page, don't continue.
    if (!$('table#course-types-datatable').length) return;

    // Turbolinks tries to fire the course init when clicking away from the page. To prevent
    // datatables from throwing an error we have to check and only initialize datatables if it
    // hasn't been initialized already.
    if ($('#course-types-datatable_wrapper').length > 0) return;

    dataTablesHandler.init('course-types-datatable', {
      pageLength: 20,
      ajax: { url: $('#course-types-datatable').data('source') },
      createdRow: appendActionsColumn,
      columns: [
        { data: 'id' },
        { data: 'title' },
        { data: 'version' },
        { data: 'infusionsoft_tag_id' },
        { data: 'webinar' },
        { data: 'summary' }
      ]
    });
  };

  const appendActionsColumn = function (row, data, dataIndex, cells) {
    const actions = `
      <td class="actions">
        <a href="/admin/course_types/${parseInt(data.id)}/">Show</a>
        <a href="/admin/course_types/${parseInt(data.id)}/edit">Edit</a>
        <a href="/admin/course_types/${parseInt(data.id)}" data-method="delete" data-confirm="Are you sure?" rel="nofollow">Delete</a>
      </td>`;
    $(row).append(actions);
  };

  return {
    init: init
  };
}());

$(document).on('turbolinks:load', function () {
  courseTypes.init();
});
