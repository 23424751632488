import Sortable from 'sortablejs';

const trainingVideos = (function () {

  let videos;
  let sortableVideos;
  let updateOrderEndpoint;

  const init = function () {
    videos = document.getElementById('trainingVideos');
    if (videos === null) return;

    updateOrderEndpoint = document.getElementById('trainingVideos')
                                  .getAttribute('data-update-order-endpoint');

    initSortable();
  };

  const initSortable = function () {
    sortableVideos = new Sortable(videos, {
      fallbackTolerance: 4,
      onUpdate: saveOrder
    });
  };

  const saveOrder = function (event) {
    $.ajax({
      type: 'POST',
      url: updateOrderEndpoint,
      dataType: 'json',
      data: {
        updated_order: sortableVideos.toArray()
      },
      success: function() {
        showSuccessIndicator(event.item)
      }
    });
  };

  const showSuccessIndicator = function (element) {
    const $element = $(element);
    $element.addClass('tr-success');
    setTimeout(function() {
      $element.removeClass('tr-success');
    }, 700);
  };

  return {
    init: init
  };
}());

$(document).on('turbolinks:load', function () {
  trainingVideos.init();
});
