import { dataTablesHandler } from 'datatables_handler';

const users = (function () {
  const init = function () {
    initUserDataTable();
  };

  const initUserDataTable = function () {
    // If we aren't on the User index page, don't continue.
    if (!$('table#users-datatable').length) return;

    // Turbolinks tries to fire the users init when clicking away from the page. To prevent
    // datatables from throwing an error we have to check and only initialize datatables if it
    // hasn't been initialized already.
    if ($('#users-datatable_wrapper').length > 0) return;

    dataTablesHandler.init('users-datatable', {
      pageLength: 20,
      ajax: { url: $('#users-datatable').data('source') },
      createdRow: appendActionsColumn,
      columns: [
        { data: 'id' },
        { data: 'fname' },
        { data: 'lname' },
        { data: 'roles' },
        { data: 'email' }
      ]
    });
  };

  const appendActionsColumn = function (row, data, dataIndex, cells) {
    const active = (data.is_active === 'true');
    const activationToggleText = active ? 'Deactivate' : 'Activate';

    const actions = `
      <td class="actions">
        <a href="/users/${parseInt(data.id)}/">Show</a>
        <a href="/users/${parseInt(data.id)}/edit">Edit</a>
        <a href="/users/${parseInt(data.id)}?user[is_active]=${!active}" data-method="patch"
           data-confirm="Are you sure you want to ${activationToggleText} ${data.fname} ${data.lname}?">
           ${activationToggleText}</a>
        <a href="/users/${parseInt(data.id)}/" data-method="delete"
           data-confirm="Are you sure you want to delete ${data.fname} ${data.lname}?">Delete</a>
      </td>`;
    $(row).append(actions);
  };

  return {
    init: init
  };
}());

$(document).on('turbolinks:load', function () {
  users.init();
});
