// https://xdsoft.net/jqplugins/datetimepicker
import 'jquery-datetimepicker';

const datetimePicker = (function () {
  $.datetimepicker.setDateFormatter('moment');
  const defaultFormat = 'MM/DD/YYYY hh:mm A';
  const iso8601Format = 'YYYY-MM-DDTHH:mm:ss';
  const datetimePickersSelector = '[data-behavior~=datetime-picker]';
  const pickerConfig = {
    format: 'MM/DD/YYYY hh:mm A', // Format: https://momentjs.com/docs/#/parsing/string-format/
    formatTime: 'hh:mm A',
    minTime: '05:00 AM',
    maxTime: '11:00 PM'
  };

  /**
   * Converts a unix timestamp to a formatted datetime string in the users local timezone
   *
   * @param {number} unixTime seconds since unix epoch
   * @param {string} format momentjs datetime format token string
   * @returns {string} e.g. "07/20/19 12:15 PM" or blank
   */
  const localDatetime = function (unixTime, format = defaultFormat) {
    if (unixTime === 0) return '';

    const momentTimestamp = moment.unix(unixTime);
    // Moment Timezone: http://momentjs.com/timezone/docs/#/using-timezones/guessing-user-timezone/
    const timezone = moment.tz.guess();

    return moment.tz(momentTimestamp, timezone).format(format);
  };

  const converTimestampToLocalTime = function (_index, element) {
    const $field = $(element);
    const unixTime = $field.data('datetime');
    const localDt = localDatetime(unixTime);

    $field.text(localDt);
  };

  // Convert all the datetime picker fields to a less ambiguous ISO 8601 format, sans timezone.
  // https://en.wikipedia.org/wiki/ISO_8601
  const convertDatefieldToISO8601 = function (_index, element) {
    const $field = $(element);

    if ($field.val()) {
      const datetimeNoTZ = moment($field.val(), defaultFormat).format(iso8601Format);
      $field.val(datetimeNoTZ);
    }
  };

  const processSubmit = function (event) {
    const $form = $(event.target);
    $form.find(datetimePickersSelector).each(convertDatefieldToISO8601);
  };

  const init = function () {
    /**
     * As a form of good practice, don't attach behavior to CSS classes. Use the data-behavior
     * attribute instead. This follows the design principle of seperation of concerns. For more
     * insight on this practice see:
     * https://blog.bigbinary.com/2012/10/10/data-behavior.html
     */
    const $datetimeFields = $(datetimePickersSelector);
    $datetimeFields.datetimepicker(pickerConfig);

    // Convert element containing unix timestamp to user's local time
    $('[data-behavior~=to-localtime]').each(converTimestampToLocalTime);

    $('[data-behavior~=to-utc]').on('submit', processSubmit);
  };

  return {
    init: init
  };
})();

$(document).on('turbolinks:load', datetimePicker.init);
