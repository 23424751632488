import { dataTablesHandler } from 'datatables_handler';

const courses = (function () {
  const init = function () {
    initCourseDataTable();
  };

  const initCourseDataTable = function () {
    // If we aren't on the Course index page, don't continue.
    if (!$('table#courses-datatable').length) return;

    // Turbolinks tries to fire the course init when clicking away from the page. To prevent
    // datatables from throwing an error we have to check and only initialize datatables if it
    // hasn't been initialized already.
    if ($('#courses-datatable_wrapper').length > 0) return;

    dataTablesHandler.init('courses-datatable', {
      pageLength: 20,
      ajax: { url: $('#courses-datatable').data('source') },
      createdRow: appendActionsColumn,
      columns: [
        { data: 'id' },
        { data: 'title' },
        { data: 'type_title' },
        { data: 'abbr' },
        { data: 'location_title' },
        { data: 'advisor_fname' },
        { data: 'advisor_lname' },
        { data: 'seats_available' },
        { data: 'day1_starts_at' },
        { data: 'day1_ends_at' },
        { data: 'day2_starts_at' },
        { data: 'day2_ends_at' },
        { data: 'price' },
        { data: 'open_for_registration' },
        { data: 'published' },
        { data: 'published_at' },
        { data: 'status' }
      ]
    });
  };

  const appendActionsColumn = function (row, data, dataIndex, cells) {
    const actions = `
      <td class="actions">
        <a href="/admin/courses/${parseInt(data.id)}/edit">Edit</a>
        <a href="/admin/courses/${parseInt(data.id)}" data-method="delete" data-confirm="Are you sure?" rel="nofollow">Destroy</a>
        <a href="/admin/courses/${parseInt(data.id)}/preview" target="_blank">Preview</a>
      </td>`;
    $(row).append(actions);
  };

  return {
    init: init
  };
}());

$(document).on('turbolinks:load', function () {
  courses.init();
});
